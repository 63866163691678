import React from 'react';

function English() {
  return (
    <div>
      <h2>Comming soon...</h2>
      
    </div>
  );
}

export default English;